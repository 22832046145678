// socket.js
import { io } from "socket.io-client";

const SOCKET_SERVER_URL = process.env.REACT_APP_WS_URL;

const createSocketDashboard = () => {
    try {
        return io(`${SOCKET_SERVER_URL}/dashboard`, {
            withCredentials: true,
            reconnection: true,
            reconnectionAttempts: 10,
            reconnectionDelay: 2000, // Start with a 2s delay
            reconnectionDelayMax: 10000, // Maximum 10s delay
            timeout: 60000, // 1 minute
        });
    } catch (error) {
        console.error('Failed to create socket connection:', error);
        // Xử lý lỗi theo cách phù hợp với ứng dụng của bạn
        return null; // Hoặc giá trị fallback khác nếu cần
    }
};

const createSocketDrawDashboard = () => {
    try {
        return io(`${SOCKET_SERVER_URL}/drawdashboard`, {
            withCredentials: true,
            reconnection: true,
            reconnectionAttempts: 10,
            reconnectionDelay: 2000, // Start with a 2s delay
            reconnectionDelayMax: 10000, // Maximum 10s delay
            timeout: 60000, // 1 minute
        });
    } catch (error) {
        console.error('Failed to create socket connection:', error);
        // Xử lý lỗi theo cách phù hợp với ứng dụng của bạn
        return null; // Hoặc giá trị fallback khác nếu cần
    }
};

export {createSocketDashboard, createSocketDrawDashboard};

import { useState, useEffect, useRef } from 'react';

const useTypingEffect = (text, typingSpeed = 100) => {
    const [displayedText, setDisplayedText] = useState('');
    const indexRef = useRef(0); // Using a ref to hold the current index

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDisplayedText((prevText) => {
                if (indexRef.current < text.trim().length) {
                    // Getting the character directly from the text using the ref's current value
                    const nextChar = text[indexRef.current];
                    indexRef.current++; // Increment the ref's current value
                    return prevText + nextChar;
                } else {
                    clearInterval(intervalId);
                    return prevText;
                }
            });
        }, typingSpeed);

        // Reset indexRef when text changes
        return () => {
            clearInterval(intervalId);
            indexRef.current = 0; 
        };
    }, [text, typingSpeed]);

    return displayedText;
}

export default useTypingEffect;
import { useState, useEffect } from 'react';
import iconHappy from '../data/img/icon_happy.png';
import iconEnergetic from '../data/img/icon_energetic.png';
import iconSad from '../data/img/icon_sad.png';
import iconSwing from '../data/img/icon_swing.png';


const useMoodList = () => {
    const [moodList, setMoodList] = useState(null);

    useEffect(() => {
        const listMood = {
            happy: {
                text: 'Happy',
                url: iconHappy,
                msg: [
                    `Happiness looks stunning on you, wear it every day!`,
                    `Shine brighter, you're the universe's favorite star!`,
                    `Joy radiates from you like sunbeams from the sun!`,
                    `Keep sprinkling that happy dust everywhere you go!`,
                    `Your happiness is the world's most infectious melody!`,
                    `You're the kind of happy that brightens even the moon's day!`,
                    `Dance in your happiness, and let the world clap along!`,
                    `You're the kind of happy that even flowers envy!`,
                    `Keep shining, your joy is a beacon for all to follow!`
                ]
            },
            energetic: {
                text: 'Energetic',
                url: iconEnergetic,
                msg: [
                    `Charge forward, your energy leads the way!`,
                    `Your energy is the spark that lights up any room!`,
                    `Ride the waves of your vibrant energy and inspire the tide!`,
                    `You're the burst of sunshine on the cloudiest day!`,
                    `With energy like yours, the universe has no choice but to dance!`,
                    `You're the electric jolt in a world of dim bulbs!`,
                    `Your zeal is the fuel that sets spirits ablaze!`,
                    `You shine so bright, even stars might need shades!`,
                    `In the symphony of life, your energy is the crescendo!`,
                    `With every step, you're recharging the world around you!`
                ]
            },
            sad: {
                text: 'Sad',
                url: iconSad,
                msg: [
                    `Even in shadows, your light finds a way to peek through.`,
                    `Every tear you shed waters the seeds of your resilience.`,
                    `Your strength echoes even in the quietest whispers of hope.`,
                    `Rainstorms always precede the most vibrant rainbows.`,
                    `In the tapestry of life, your courage is the golden thread.`,
                    `Even the moon needs to hide sometimes, but it always returns to shine.`,
                    `Your spirit, even in pain, sings songs of resilience.`,
                    `Every sunset holds the promise of a brighter dawn.`,
                    `Embrace the storm, for calm seas await you ahead.`,
                    `Your heart's depth is the birthplace of tomorrow's joy.`
                ]
            },
            swing: {
                text: 'Mood Swing',
                url: iconSwing,
                msg: [
                    `Embrace the dance of your emotions; each step has its own rhythm.`,
                    `Your mood swings are like brushstrokes on the canvas of your life's masterpiece.`,
                    `In the orchestra of your emotions, even dissonance can create a beautiful symphony.`,
                    `Your moods are the colorful chapters that make your story captivating.`,
                    `Every emotional wave carries the potential for a thrilling ride.`,
                    `You're the artist of your emotions, and every shade adds depth to your masterpiece.`,
                    `Like a kaleidoscope, your mood swings create a unique pattern of beauty.`,
                    `Your emotions are the spices that make the recipe of your life extraordinary.`,
                    `Even in the rollercoaster of moods, your spirit remains the constant conductor.`,
                    `Your ever-changing emotions are the stars of your celestial journey.`
                ]
            },
        }

        setMoodList(listMood);

    }, []);

    return { moodList };
}

export default useMoodList;
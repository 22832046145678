import { useState, useEffect } from "react";
import { HelmetMoodBoard } from "../components/CustomHelmet";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import api from "../utils/api"; // Import the Axios instance you created
import getTodayDateGMT7 from "../utils/getTodayDateGMT7";
import useElementFullHeight from "../hooks/useElementFullHeight";
import Footer from "../components/Footer";

// Đăng ký các thành phần của Chart.js
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const AnalyticsPage = () => {
  const [dataMoodList, setDataMoodList] = useState(false);

  useEffect(() => {
    api
      .get(`/mood-data`)
      .then((response) => {
        const data = response.data.data;
        setDataMoodList(data);
      })
      .catch((error) => {
        console.error(`Error fetching mood data:`, error);
      });
  }, []);

  //container height
  const elementHeight = useElementFullHeight(".element-to-exclude");

  // Xử lý dữ liệu để phù hợp với định dạng của biểu đồ
  // Xử lý dữ liệu để phù hợp với định dạng của biểu đồ
  const chartData = {
    labels: dataMoodList ? dataMoodList.map((entry) => entry.date) : [],
    datasets: [
      {
        label: "Happy",
        data: dataMoodList ? dataMoodList.map(entry => (entry.count.happy / (entry.count.happy + entry.count.energetic + entry.count.sad + entry.count.swing)) * 100) : [],
        backgroundColor: "rgba(255, 206, 86, 0.8)",
      },
      {
        label: "Energetic",
        data: dataMoodList
          ? dataMoodList.map(
              (entry) =>
                (entry.count.energetic /
                  (entry.count.happy +
                    entry.count.energetic +
                    entry.count.sad +
                    entry.count.swing)) *
                100
            )
          : [],
        backgroundColor: "rgba(75, 192, 192, 0.8)",
      },
      {
        label: "Sad",
        data: dataMoodList
          ? dataMoodList.map(
              (entry) =>
                (entry.count.sad /
                  (entry.count.happy +
                    entry.count.energetic +
                    entry.count.sad +
                    entry.count.swing)) *
                100
            )
          : [],
        backgroundColor: "rgba(153, 102, 255, 0.8)",
      },
      {
        label: "Swing",
        data: dataMoodList
          ? dataMoodList.map(
              (entry) =>
                (entry.count.swing /
                  (entry.count.happy +
                    entry.count.energetic +
                    entry.count.sad +
                    entry.count.swing)) *
                100
            )
          : [],
        backgroundColor: "rgba(255, 99, 132, 0.8)",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            // Lấy dữ liệu của mood
            const datasetLabel = context.dataset.label || "";
            const value = context.raw;
            // Tính phần trăm
            const percentage = value.toFixed(2);
            // Trả về giá trị theo định dạng
            return `${datasetLabel}: ${percentage}%`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
        max: 100, // Đặt giới hạn tối đa của trục y là 100%
        ticks: {
          callback: function (value) {
            return value + "%"; // Hiển thị giá trị phần trăm trên trục y
          },
        },
      },
    },
  };

  return (
    <div className="analyticspage" id="analyticspage">
      <HelmetMoodBoard />
      <div className="container" style={{ minHeight: `${elementHeight}px` }}>
        <section>
          <h1 className="main-title">
            Emotions Board <b>Analytics</b>
          </h1>
          {/* <div className="table-wrapper">
            {dataMoodList ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Happy</th>
                    <th>Energetic</th>
                    <th>Sad</th>
                    <th>Swing</th>
                  </tr>
                </thead>
                <tbody>
                  {dataMoodList.map((entry) => {
                    const totalForDate =
                      entry.count.happy +
                      entry.count.energetic +
                      entry.count.sad +
                      entry.count.swing;
                    return (
                      <tr key={entry.date}>
                        <td>
                          {entry.date}{" "}
                          {entry.date === getTodayDateGMT7() ? "(Today)" : ""}
                        </td>
                        <td>
                          <b>{entry.count.happy}</b>
                          <small>
                            {((entry.count.happy / totalForDate) * 100).toFixed(
                              1
                            )}
                            %
                          </small>
                        </td>
                        <td>
                          <b>{entry.count.energetic}</b>
                          <small>
                            {(
                              (entry.count.energetic / totalForDate) *
                              100
                            ).toFixed(1)}
                            %
                          </small>
                        </td>
                        <td>
                          <b>{entry.count.sad}</b>
                          <small>
                            {((entry.count.sad / totalForDate) * 100).toFixed(
                              1
                            )}
                            %
                          </small>
                        </td>
                        <td>
                          <b>{entry.count.swing}</b>
                          <small>
                            {((entry.count.swing / totalForDate) * 100).toFixed(
                              1
                            )}
                            %
                          </small>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <i>There is no data available.</i>
            )}
          </div> */}
          {/* Biểu đồ */}
          {dataMoodList && (
            <div className="chart-container">
              <div className="chart-wrapper">
                <Bar data={chartData} options={chartOptions} />
              </div>
            </div>
          )}
        </section>
      </div>
      <div className="container element-to-exclude">
        <Footer />
      </div>
    </div>
  );
};

export default AnalyticsPage;

import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './notify.scss';

const showNotification = (type, message) => {
    switch (type) {
        case 'info':
            NotificationManager.info(message, 'Info', 3000);
            break;
        case 'success':
            NotificationManager.success(message, 'Success', 3000);
            break;
        case 'warning':
            NotificationManager.warning(message, 'Warning', 3000);
            break;
        case 'error':
            NotificationManager.error(message, 'Error', 3000);
            break;
        default:
            NotificationManager.info(message);
    }
};

export default showNotification;
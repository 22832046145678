import './AdminConfigDrawPage.scss'
import { useState, useEffect } from 'react';
import api from '../../utils/api';
import showNotification from '../../utils/notify';
import useElementFullHeight from '../../hooks/useElementFullHeight';

const AdminConfigDrawPage = () => {
    const DATA_URL = process.env.REACT_APP_DATA_URL;

    const [dataDrawList, setdataDrawList] = useState([]);

    const [queryStatus, setQueryStatus] = useState(0);

    const [activeControlBtn, setActiveControlBtn] = useState(true);

    //container height
    const elementHeight = useElementFullHeight('.element-to-exclude');

    //update waiting data
    const updateDraw = (drawFilename, newStatus) => {
        const params = { drawFileList: [drawFilename], newStatus: newStatus }
        api.put(`/draw`, params)
            .then(response => {
                showNotification("success", response.data.success);
                const newData = dataDrawList.filter(item => item.drawFilename !== drawFilename);

                // Update the state with the new array
                setdataDrawList(newData);
            })
            .catch(error => {
                showNotification("error", error);
            });
    }

    const getDrawData = (status) => {
        api.get(`/draw/${status}`)
            .then(response => {
                const data = response.data.data;
                setdataDrawList(data);
            })
            .catch(error => {
                console.error(`Error fetching mood data}:`, error);
            });
    }

    //Reload Dashboard
    // const reloadDasboard = () => {
    //     api.post(`/reload-draw-dashboard`)
    //         .then(response => {
    //             setActiveControlBtn(false);
    //             showNotification('success', response.data.message);

    //             setTimeout(() => {
    //                 setActiveControlBtn(true);
    //             }, 10000)
    //         })
    //         .catch(error => {
    //             console.error(`Error.`, error);
    //             showNotification('error', error);
    //         });
    // }

    // //getData Dashboard
    // const getDataDasboard = () => {
    //     api.post(`/get-data-draw-dashboard`)
    //         .then(response => {
    //             setActiveGetDataDasboard(false);
    //             showNotification('success', response.data.message);

    //             setTimeout(() => {
    //                 setActiveGetDataDasboard(true);
    //             }, 10000)
    //         })
    //         .catch(error => {
    //             console.error(`Error.`, error);
    //             showNotification('error', error);
    //         });
    // }

    const controlDrawDashboard = (action) => {
        if (action) {
            api.post(`/control-draw-dashboard`, { action })
                .then(response => {
                    setActiveControlBtn(false);
                    showNotification('success', response.data.message);

                    setTimeout(() => {
                        setActiveControlBtn(true);
                    }, 10000)
                })
                .catch(error => {
                    console.error(`Error.`, error);
                    showNotification('error', error);
                });
        }
    }

    useEffect(() => {
        //Get waiting list
        getDrawData(0);

    }, []);



    return (
        <div className='configdrawpage' id="configdrawpage">
            <div className='container' style={{ minHeight: `${elementHeight}px` }}>
                <section>
                    <h1 className='main-title'>Drawing <b>Management</b></h1>

                    <div className='config-block'>
                        <button className='btn btn-info' onClick={() => { controlDrawDashboard("reload") }} type="button" disabled={!activeControlBtn}>
                            Refresh all Dashboard
                        </button>
                        <button className='btn btn-info' onClick={() => { controlDrawDashboard("getdata") }} type="button" disabled={!activeControlBtn}>
                            Get Data all dashboard
                        </button>
                    </div>

                    <div className='config-block'>
                        <div style={{ width: "100%" }}>
                            <div className='form-group'>
                                <label>Draw status</label>
                                <select
                                    id="selectStatus"
                                    value={queryStatus}
                                    onChange={(e) => setQueryStatus(e.target.value)}>
                                    <option value={"all"}>All</option>
                                    <option value={0}>Waiting</option>
                                    <option value={1}>Approved</option>
                                    <option value={-1}>Rejected</option>

                                </select>
                                <button className='btn' onClick={() => getDrawData(queryStatus)}>Find</button>
                            </div>
                        </div>
                        <div className='table-wrapper'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th width={55} style={{ textAlign: "center" }}>No</th>
                                        <th>Info</th>
                                        <th width={150}>Draw Image</th>
                                        <th width={100}>Status</th>
                                        <th width={130}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataDrawList.length ? (
                                        dataDrawList.map((item, index) => (
                                            <tr key={index}>
                                                <td style={{ textAlign: "center" }}>{index + 1}</td>
                                                <td style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
                                                    <p>Sender: {item.sender || "--"}</p>
                                                    <p>Message: {item.msg || "--"}</p>
                                                </td>
                                                <td>
                                                    <div className='bg-template'>
                                                        <img width={150} height={150} src={`${DATA_URL}/${item.drawFilename}`} alt='' />
                                                    </div>
                                                </td>
                                                <td> {parseInt(item.status) === 0 ? 'Waiting' : ''}
                                                    {parseInt(item.status) === 1 ? 'Approved' : ''}
                                                    {parseInt(item.status) === -1 ? 'Rejected' : ''}
                                                </td>
                                                <td className='btn-group'>
                                                    {parseInt(item.status) !== 0 ? (
                                                        <button className="btn btn-small btn-secondary" onClick={() => updateDraw(item.drawFilename, 0)}>Set Waiting</button>
                                                    ) : ''}
                                                    {parseInt(item.status) !== 1 ? (
                                                        <button className="btn btn-small btn-info" onClick={() => updateDraw(item.drawFilename, 1)}>Approve</button>
                                                    ) : ''}
                                                    {parseInt(item.status) !== -1 ? (
                                                        <button className="btn btn-small btn-danger" onClick={() => updateDraw(item.drawFilename, -1)}>Reject</button>
                                                    ) : ''}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={5} style={{ textAlign: "center" }}>No data</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                        </div>
                    </div>

                </section>
            </div>
        </div>
    )
}

export default AdminConfigDrawPage;
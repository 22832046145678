// CustomHelmet.js
import React from 'react';
import { Helmet } from 'react-helmet';

const CustomMyOwnHelmet = ({ title = "", ogTitle = "", ogImage = "", description = "" }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta property="og:title" content={ogTitle} />
            <meta property="og:image" content={ogImage} />
            <meta property="og:description" content={description} />
            <meta name="description" content={description} />
        </Helmet>
    );
};

const HelmetMoodBoard = () => {
    return (
        <Helmet>
            <title>VML VIETNAM | Emotions board</title>
            <meta property="og:title" content="VML VIETNAM | Emotions board" />
            <meta property="og:image" content={`${process.env.REACT_APP_URL}/og-image.jpg`} />
            <meta property="og:description" content="How do you feel today?" />
            <meta name="description" content='How do you feel today?' />
        </Helmet>
    );
};

const HelmetXmasDraw = () => {
    return (
        <Helmet>
            <title>VML VIETNAM | SHARE YOUR WISH</title>
            <meta property="og:title" content="VML VIETNAM | SHARE YOUR WISH" />
            <meta property="og:image" content={`${process.env.REACT_APP_URL}/og-image-xmas.jpg`} />
            <meta property="og:description" content="Share your wish with us?" />
            <meta name="description" content='Share your wish with us?' />
        </Helmet>
    );
};

export { CustomMyOwnHelmet, HelmetMoodBoard, HelmetXmasDraw };

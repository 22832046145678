import { useState, useEffect } from 'react';
import './DrawAnalyticsPage.scss'
import { HelmetXmasDraw } from '../components/CustomHelmet';

import useElementFullHeight from '../hooks/useElementFullHeight';
import changeMainBackground from '../utils/changeMainBackground';
import SnowFall from '../components/SnowFall';
import { useNavigate } from 'react-router-dom';

import api from '../utils/api';

import urlTemplate from '../data/img/draw/template.jpg';

const DrawAnalyticsPage = () => {
    const navigate = useNavigate();
    const elementHeight = useElementFullHeight('.element-to-exclude');
    const DATA_URL = process.env.REACT_APP_DATA_URL;

    const drawingStyle = {
        backgroundImage: `url(${urlTemplate})`,
        backgroundSize: 'cover', // You can use 'contain' or other values based on your requirements
    };

    const [drawDataList, setDrawDataList] = useState([]);
    const [drawData, setDrawData] = useState({
        drawFilename: "",
        msg: "",
        sender: ""
    });

    const getData = () => {
        api.get(`/draw/1`)
            .then(response => {
                const data = response.data.data;
                setDrawDataList(data);
            })
            .catch(error => {
                console.error(`Error fetching mood data}:`, error);
            });

    }

    useEffect(() => {
        changeMainBackground("christmas");

        getData();

        // Cleanup: disconnect the socket when the component unmounts
        return () => {
        };

    }, []);

    useEffect(() => {
        let indx = 0;

        let intervalRenderDraw;

        clearInterval(intervalRenderDraw);

        const setupDrawData = (_drawData) => {
            setDrawData(_drawData);
        }

        if (drawDataList[indx] && drawDataList[indx]["_id"]) {
            setupDrawData(drawDataList[indx]);
            indx++;
        }

        intervalRenderDraw = setInterval(() => {
            if (!drawDataList[indx]) {
                indx = 0;
            }

            if (drawDataList[indx] && drawDataList[indx]["_id"]) {
                setupDrawData(drawDataList[indx]);
                indx++;
            }

        }, 500)

        return () => {
            clearInterval(intervalRenderDraw);
        };

    }, [drawDataList]);


    return (
        <div className='drawanalyticspage' id="drawanalyticspage">
            <HelmetXmasDraw />
            <div className='container' style={{ minHeight: `${elementHeight}px` }}>
                <SnowFall />
                <div className='snowflake-group left'>
                    <div className='snowflake'></div>
                    <div className='snowflake'></div>
                    <div className='snowflake'></div>
                    <div className='snowflake'></div>
                    <div className='snowflake'></div>
                </div>
                <div className='snowflake-group right'>
                    <div className='snowflake'></div>
                    <div className='snowflake'></div>
                    <div className='snowflake'></div>
                    <div className='snowflake'></div>
                    <div className='snowflake'></div>
                    <div className='snowflake'></div>
                </div>
                <div className='christmas-card'>
                    <div className='markers'>
                        <div className='marker'></div>
                        <div className='marker'></div>
                        <div className='marker'></div>
                        <div className='marker'></div>
                    </div>
                    <div className='card-container'>
                        <div className='drawing' style={drawingStyle}>
                            <img src={`${DATA_URL}/${drawData.drawFilename}`} alt="" />
                        </div>
                        <div className='msg content-align-center'>
                            {/* <div className='title'>
                                <h1>
                                    <img src={urlCardTitle} alt='Merry Christmas - VML Vietnam' />
                                </h1>
                            </div> */}
                            <div className='content-analytics'>
                                <label>{String(drawDataList.length).padStart(3, '0') || "---"}</label>
                                <p>
                                    wishes have been posted.<br />
                                    Merry Christmas Everyone!
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default DrawAnalyticsPage;
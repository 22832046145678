import { useState, useEffect } from 'react';
import './DrawGalleryPage.scss'
import { HelmetXmasDraw } from '../components/CustomHelmet';

import useElementFullHeight from '../hooks/useElementFullHeight';
import changeMainBackground from '../utils/changeMainBackground';
import Marquee from "react-fast-marquee";

import DrawFrame from '../components/DrawFrame';

import api from '../utils/api';

const DrawGalleryPage = () => {

    const elementHeight = useElementFullHeight('.element-to-exclude');
    const DATA_URL = process.env.REACT_APP_DATA_URL;

    const [drawDataList, setDrawDataList] = useState([]);
    const [drawDataList2, setDrawDataList2] = useState([]);
    const [drawDataList3, setDrawDataList3] = useState([]);
    const [drawDataList4, setDrawDataList4] = useState([]);
    // const [drawDataList5, setDrawDataList5] = useState([]);

    const getData = () => {
        api.get(`/draw/1`)
            .then(response => {
                const data = response.data.data;
                const dataReversed = [...data].reverse();
                const dataShuffled = shuffleArray(data);
                const dataShuffledReversed = [...dataShuffled].reverse();
                setDrawDataList(data);
                setDrawDataList2(dataShuffled);
                setDrawDataList3(dataReversed);
                setDrawDataList4(dataShuffledReversed);
            })
            .catch(error => {
                console.error(`Error fetching mood data}:`, error);
            });

    }

    useEffect(() => {
        changeMainBackground("christmas");

        getData();

        // Cleanup: disconnect the socket when the component unmounts
        return () => {
        };

    }, []);

    const shuffleArray = (arr) => {
        const shuffledArray = [...arr];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }

        return shuffledArray;
    }

    return (
        <div className='drawgallerypage' id="drawgallerypage">
            <HelmetXmasDraw />
            <div className='' style={{ minHeight: `${elementHeight}px` }}>
                <div className='gallery' style={{ minHeight: `${elementHeight}px` }}>
                    <Marquee speed={25} direction='right'>
                        {drawDataList.map((item) => (
                            <DrawFrame key={`${item._id + Math.round(Math.random() * 1E6)}`} url={`${DATA_URL}/${item.drawFilename}`} />
                        ))}
                    </Marquee>
                    <Marquee speed={25}>
                        {drawDataList2.map((item) => (
                            <DrawFrame key={`${item._id + Math.round(Math.random() * 1E6)}`} url={`${DATA_URL}/${item.drawFilename}`} />
                        ))}
                    </Marquee>
                    <Marquee speed={25} direction='right'>
                        {drawDataList3.map((item) => (
                            <DrawFrame key={`${item._id + Math.round(Math.random() * 1E6)}`} url={`${DATA_URL}/${item.drawFilename}`} />
                        ))}
                    </Marquee>
                    <Marquee speed={25}>
                        {drawDataList4.map((item) => (
                            <DrawFrame key={`${item._id + Math.round(Math.random() * 1E6)}`} url={`${DATA_URL}/${item.drawFilename}`} />
                        ))}
                    </Marquee>
                    {/* <Marquee speed={25} direction='right'>
                        {drawDataList5.map((item) => (
                            <DrawFrame key={`${item._id + Math.round(Math.random() * 1E6)}`} url={`${DATA_URL}/${item.drawFilename}`} />
                        ))}
                    </Marquee> */}
                    <div className='content-analytics'>
                        <label>{String(drawDataList.length).padStart(3, '0') || "---"}</label>
                        <p>
                            wishes have been posted.<br />
                            Merry Christmas Everyone!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DrawGalleryPage;
import "./NotFoundPage.scss";
import { Link } from "react-router-dom";
import useElementFullHeight from "../hooks/useElementFullHeight";

const HomePage = () => {
  //container height
  const elementHeight = useElementFullHeight(".element-to-exclude");

  return (
    <div className="homepage" id="homepage">
      <div className="container" style={{ minHeight: `${elementHeight}px` }}>
        <section>
          <h1 className="main-title">
            <b>VML</b> VIETNAM
          </h1>

          <ul className="homepage-cards">
            <li className="card">
              <h2>MOODBOARD</h2>
              <ul>
                <li>
                  <Link key="goto-mood" to="/mood">
                    Submit your mood
                  </Link>
                </li>
                <li>
                  <Link key="goto-mood-dashboard" to="/mood-dashboard">
                    Dashboard page (realtime)
                  </Link>
                </li>
                <li>
                  <Link key="goto-mood-analytics" to="/mood-analytics">
                    Analytics
                  </Link>
                </li>
                <li>
                  <Link key="goto-mood-config" to="/mood-config">
                    Admin: Mood config (authentication required)
                  </Link>
                </li>
              </ul>
            </li>

            <li className="card">
              <h2>SHARE YOUR WISH</h2>
              <ul>
                <li>
                  <Link key="goto-draw" to="/draw">
                    Submit your wish with a Drawing
                  </Link>
                </li>
                <li>
                  <Link key="goto-draw-dashboard" to="/draw-dashboard">
                    Draw Dashboard page (realtime)
                  </Link>
                </li>
                <li>
                  <Link key="goto-draw-analytics" to="/draw-analytics">
                    Draw Analytics page
                  </Link>
                </li>
                <li>
                  <Link key="goto-draw-gallery" to="/draw-gallery">
                    Draw Gallery page
                  </Link>
                </li>
                <li>
                  <Link key="goto-draw-config" to="/draw-config">
                    Admin: Draw config (authentication required)
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default HomePage;

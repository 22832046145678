import { useState, useEffect, useRef } from 'react';
import './DrawPage.scss'
import { HelmetXmasDraw } from '../components/CustomHelmet';

import useElementFullHeight from '../hooks/useElementFullHeight';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import showNotification from '../utils/notify';
import api from '../utils/api';
import changeMainBackground from '../utils/changeMainBackground';

import urlTemplate from '../data/img/draw/template.jpg'


const DrawPage = () => {

    const elementHeight = useElementFullHeight('.element-to-exclude');

    const theDrawRef = useRef(null);

    const [drawConfig, setDrawConfig] = useState({
        strokeWidth: 4,
        strokeColor: "#000000",
        backgroundImage: urlTemplate,
        eraserWidth: 8,
        eraserStatus: false
    });

    const [sendInfo, setSendInfo] = useState({
        sender: "",
        msg: ""
    });

    const [showPopup, setShowPopup] = useState(false);

    const handleSubmitDraw = (sender = "", msg = "") => {
        theDrawRef.current.exportPaths().then((paths) => {
            if (!paths.length) {
                showNotification('error', "Please complete your drawing before submitting.");
                return;
            } else {
                theDrawRef.current.exportSvg()
                    .then(svgString => {
                        // Create a Blob from the SVG string
                        const blob = new Blob([svgString], { type: 'image/svg+xml' });

                        // Create an Object URL from the Blob
                        const objectURL = URL.createObjectURL(blob);

                        // Create an Image element
                        const img = new Image();

                        // Set the Image source to the Object URL
                        img.src = objectURL;

                        // When the Image has loaded, you can use it or convert to base64
                        img.onload = function () {
                            // Create a canvas element
                            const canvas = document.createElement('canvas');
                            const context = canvas.getContext('2d');

                            // Set the canvas size to the Image size
                            canvas.width = 1000;
                            canvas.height = 1000;

                            // Draw the Image onto the canvas
                            context.drawImage(img, 0, 0, 1000, 1000);

                            // Get the data URL of the canvas as a PNG image
                            const dataUrl = canvas.toDataURL('image/png');

                            const params = { drawData: dataUrl, sender: sender, msg: msg, fileType: 'png' }

                            //start posting to Database
                            api.post(`/add-draw`, params)
                                .then(response => {
                                    showNotification('success', (response && response.data && response.data.success)
                                        ? response.data.success : "Something went wrong 😭. Please try again.");

                                    //clear canvas
                                    if (response && response.data && response.data.success) {
                                        handleClearCanvas();
                                    }
                                })
                                .catch(error => {
                                    console.error(`Error adding draw.`, error);
                                });

                        };

                        return;

                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
        })

    }

    const handleCanvasUpdate = (canvasPath) => {

        if (canvasPath.length) {
            localStorage.setItem('canvasPath', JSON.stringify(canvasPath));
        }

    };

    const handleClearCanvas = () => {
        theDrawRef.current.clearCanvas();
        localStorage.setItem('canvasPath', JSON.stringify([]));
    }

    useEffect(() => {
        changeMainBackground("christmas");

        const canvasPath = JSON.parse(localStorage.getItem('canvasPath'));

        if (canvasPath && canvasPath.length && theDrawRef && theDrawRef.current) {
            theDrawRef.current.loadPaths(canvasPath)
            showNotification("info", `Your incomplete Drawing has been restored.`);
        }

        return () => {
            // Perform cleanup or actions before the component is unmounted
            console.log('Component will unmount');
        };
    }, [])

    return (
        <div className='drawpage' id="drawpage">
            <HelmetXmasDraw />
            <div className='container' style={{ minHeight: `${elementHeight}px` }}>
                <section>
                    <h1 className='main-title'>Your <b>Xmas</b> Imagination</h1>
                    <div className='drawing-container'>
                        <ReactSketchCanvas
                            ref={theDrawRef}
                            strokeWidth={drawConfig.strokeWidth || 4}
                            strokeColor={drawConfig.strokeColor || "black"}
                            backgroundImage={drawConfig.backgroundImage}
                            eraserWidth={drawConfig.strokeWidth || 6}
                            canvasColor="transparent"
                            onChange={handleCanvasUpdate}
                        />
                    </div>
                    <div className='action-container'>
                        <div className='action-list'>
                            <button className='btn' onClick={() => theDrawRef.current.undo()}>Undo</button>
                            <button className='btn' onClick={() => theDrawRef.current.redo()}>Redo</button>
                            <button className='btn' onClick={() => handleClearCanvas()}>Clear All</button>
                        </div>
                        <div className='pen-options'>
                            <div className='stroke-color'>
                                <label htmlFor="penColorInput" style={{ "--preview-color": drawConfig.strokeColor }}>
                                    <b>Pen<br />Color</b>
                                </label>
                                <input
                                    type="color"
                                    id="penColorInput"
                                    value={drawConfig.strokeColor}
                                    onChange={(e) => {
                                        setDrawConfig((prevDrawConfig) => ({
                                            ...prevDrawConfig,
                                            strokeColor: e.target.value,
                                        }));
                                    }}
                                />
                            </div>
                            <div className='stroke-width'>
                                <div className="range-slider">
                                    <label><b>Pen<br />Size</b></label>
                                    <input className="range" type="range" value={drawConfig.strokeWidth}
                                        onChange={(e) => {
                                            setDrawConfig((prevDrawConfig) => ({
                                                ...prevDrawConfig,
                                                strokeWidth: e.target.value,
                                            }));
                                        }}
                                        min="1" max="20" />
                                    <span className="value">{drawConfig.strokeWidth}</span>
                                </div>
                            </div>
                            <div className='stroke-eraser'>
                                <label>
                                    <input
                                        type="checkbox"
                                        value={drawConfig.eraserStatus}
                                        onChange={(e) => {
                                            setDrawConfig((prevDrawConfig) => ({
                                                ...prevDrawConfig,
                                                eraserStatus: e.target.checked,
                                            }));
                                            theDrawRef.current.eraseMode(e.target.checked);
                                        }}
                                    />
                                    <span className={drawConfig.eraserStatus ? 'active' : ''}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23px" height="23px" viewBox="0 0 24 24" fill="none">
                                            <path d="M11.4096 5.50506C13.0796 3.83502 13.9146 3 14.9522 3C15.9899 3 16.8249 3.83502 18.4949 5.50506C20.165 7.1751 21 8.01013 21 9.04776C21 10.0854 20.165 10.9204 18.4949 12.5904L14.3017 16.7837L7.21634 9.69828L11.4096 5.50506Z" fill="#1C274C" />
                                            <path d="M6.1557 10.759L13.2411 17.8443L12.5904 18.4949C12.2127 18.8727 11.8777 19.2077 11.5734 19.5H21C21.4142 19.5 21.75 19.8358 21.75 20.25C21.75 20.6642 21.4142 21 21 21H9C7.98423 20.9747 7.1494 20.1393 5.50506 18.4949C3.83502 16.8249 3 15.9899 3 14.9522C3 13.9146 3.83502 13.0796 5.50506 11.4096L6.1557 10.759Z" fill="#1C274C" />
                                        </svg>
                                    </span>
                                </label>
                            </div>

                        </div>
                        <div className='submit-zone'>
                            <button className='btn' onClick={() => handleSubmitDraw()}>Submit as Anonymous</button>
                            <button className='btn' onClick={() => { setShowPopup(true) }}>Submit with Message 💌</button>
                        </div>
                    </div>
                </section>

            </div>

            <div className={`form-popup${showPopup ? ' show' : ''}`}>
                <div className='form-container'>
                    <div className='form-header'><span onClick={() => { setShowPopup(false) }}>Close</span></div>
                    <div className='form-group'>
                        <label>Sender: <small>{sendInfo.sender.length}/30</small></label>
                        <input type='text' minLength={0} maxLength={30} placeholder='Your Name'
                            value={sendInfo.sender}
                            onChange={(e) => {
                                setSendInfo((prevData) => ({
                                    ...prevData,
                                    sender: e.target.value,
                                }));
                            }} />
                    </div>
                    <div className='form-group'>
                        <label>Message: <small>{sendInfo.msg.length}/230</small></label>
                        <textarea maxLength="230" placeholder="Your messages" rows="8"
                            value={sendInfo.msg}
                            onChange={(e) => {
                                setSendInfo((prevData) => ({
                                    ...prevData,
                                    msg: e.target.value,
                                }));
                            }}>
                        </textarea>
                    </div>
                    <div className='form-group'>
                        <button className='btn' onClick={() => {
                            handleSubmitDraw(sendInfo.sender, sendInfo.msg)
                            setShowPopup(false)
                        }}>Submit</button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DrawPage;
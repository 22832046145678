import './AdminConfigPage.scss';
import { useState, useEffect } from 'react';
import api from '../../utils/api';
import showNotification from '../../utils/notify';
import useElementFullHeight from '../../hooks/useElementFullHeight';

const AdminConfigPage = () => {

    const [themeList, setThemeList] = useState([]);

    const [currentTheme, setCurrentTheme] = useState("");
    const [newTheme, setNewTheme] = useState("");

    const [currentSeedingCount, setCurrentSeedingCount] = useState("");
    const [newSeedingCount, setNewSeedingCount] = useState("");

    const [activeReloadDashboard, setActiveReloadDashboard] = useState(true);


    //Submit update theme
    const submitUpdateTheme = () => {
        api.put(`/mood-theme`, { data: newTheme })
            .then(response => {
                const data = response.data;
                setCurrentTheme(data.data);
                showNotification('success', data.message);
            })
            .catch(error => {
                console.error(`Error update mood theme.`, error);
                showNotification('error', error);
            });
    }

    //Submit update new seeding count
    const submitUpdateSeedingCount = () => {
        api.put(`/mood-seeding`, { data: newSeedingCount })
            .then(response => {
                const data = response.data;
                setCurrentSeedingCount(data.data);
                showNotification('success', data.message);
            })
            .catch(error => {
                console.error(`Error update seeding count.`, error);
                showNotification('error', error);
            });
    }

    //Reload Dashboard
    const reloadDasboard = () => {
        api.post(`/reload-dashboard`, {})
            .then(response => {
                setActiveReloadDashboard(false);
                showNotification('success', response.data.message);

                setTimeout(() => {
                    setActiveReloadDashboard(true);
                }, 10000)
            })
            .catch(error => {
                console.error(`Error.`, error);
                showNotification('error', error);
            });
    }

    //container height
    const elementHeight = useElementFullHeight('.element-to-exclude');

    //onChange input seeding count
    const handleChangeSeedingCount = (e) => {
        // Parse the input value as an integer
        let newValue = parseInt(e.target.value, 10);

        // Check if the new value is within the specified range
        if (isNaN(newValue) || newValue < 0) {
            newValue = 0;
        } else if (newValue > 50) {
            newValue = 50;
        }

        // Update the state with the valid value
        setNewSeedingCount(newValue);
    };

    //Message
    const [messageList, setMessageList] = useState([]);
    const [currentMessageList, setCurrentMessageList] = useState([]);
    const [editMessageIndex, setEditMessageIndex] = useState(-1);
    const [editMessageValue, setEditMessageValue] = useState('');

    const addMessage = (item) => {
        setMessageList([...messageList, item]);
    };

    const deleteMessage = (index) => {
        const updatedmessageList = messageList.filter((_, i) => i !== index);
        setMessageList(updatedmessageList);
    };

    const toggleEditMessageMessage = (index, item) => {
        if (editMessageIndex === index) {
            // Save the edited value and exit edit mode
            updateMessageItem(index, editMessageValue);
        } else {
            // Enter edit mode
            setEditMessageIndex(index);
            setEditMessageValue(item);
        }
    };

    const updateMessageItem = (index, newValue) => {
        const updatedmessageList = [...messageList];
        updatedmessageList[index] = newValue;
        setMessageList(updatedmessageList);
        setEditMessageIndex(-1); // Exit edit mode
    };


    //Submit update new message list
    const submitUpdateMessageList = () => {
        api.put(`/mood-message`, { data: messageList })
            .then(response => {
                showNotification('success', response.data.message);
                setCurrentMessageList(messageList);
            })
            .catch(error => {
                console.error(`Error update seeding count.`, error);
                showNotification('error', error);
            });
    }


    useEffect(() => {
        //Get current theme
        api.get(`/mood-theme`)
            .then(response => {
                setThemeList(response.data.data.data);
                setCurrentTheme(response.data.data["value"] || "")
                setNewTheme(response.data.data["value"] || "")
            })
            .catch(error => {
                console.error(`Error fetching mood theme.`, error);
            });

        //Get current seeding count
        api.get(`/mood-seeding`)
            .then(response => {
                setCurrentSeedingCount(response.data.data || "")
                setNewSeedingCount(response.data.data || "")
            })
            .catch(error => {
                console.error(`Error fetching seeding count.`, error);
            });

        //Get current message
        api.get(`/mood-message`)
            .then(response => {
                setMessageList(response.data.data || [])
                setCurrentMessageList(response.data.data || [])
            })
            .catch(error => {
                console.error(`Error fetching message list.`, error);
            });


    }, []);



    return (
        <div className='configpage' id="configpage">
            <div className='container' style={{ minHeight: `${elementHeight}px` }}>
                <section>
                    <h1 className='main-title'>Emotions Board <b>Configuration</b></h1>

                    <div className='config-block'>
                        <label>Theme: </label>
                        <div className={`input-wrap ${(newTheme !== false && newTheme !== currentTheme) ? `active` : ``}`}>
                            <select
                                id="selectTheme"
                                value={newTheme}
                                onChange={(e) => setNewTheme(e.target.value)}>
                                {themeList ? (
                                    themeList.map((theme, index) => (
                                        <option key={index} value={theme.themeVal}>{theme.themeLabel}</option>
                                    ))
                                ) : ""}

                            </select>
                        </div>
                        <button className='btn btn-info' onClick={submitUpdateTheme} type="button" disabled={!(newTheme !== false && newTheme !== currentTheme)}>Save</button>
                    </div>

                    <div className='config-block'>
                        <label>Seeding count: </label>
                        <div className={`input-wrap ${(newSeedingCount !== "" && newSeedingCount !== currentSeedingCount) ? `active` : ``}`}>
                            <input type='number' min={0} max={50} value={newSeedingCount} onChange={handleChangeSeedingCount} />
                        </div>
                        <button className='btn btn-info' onClick={submitUpdateSeedingCount} type="button" disabled={!(newSeedingCount !== "" && newSeedingCount !== currentSeedingCount)}>Save</button>
                    </div>


                    <div className='config-block'>
                        <label>
                            Click the button to reload all Dashboard Pages.
                        </label>
                        <button className='btn btn-info' onClick={reloadDasboard} type="button" disabled={!activeReloadDashboard}>
                            Reload
                        </button>
                    </div>

                    <div className='config-block'>
                        <label>Message List</label>
                        {messageList ? (
                            <>
                                <div className='item-list'>
                                    <ul>
                                        {messageList.map((item, index) => (
                                            <li key={index}>
                                                {editMessageIndex === index ? (
                                                    <div className='item'>
                                                        <div className="input-wrap txt-container">
                                                            <input
                                                                type="text"
                                                                value={editMessageValue}
                                                                onChange={(e) => setEditMessageValue(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className='btn-wrap'>
                                                            <button className='btn btn-info' onClick={() => toggleEditMessageMessage(index, item)}>Update</button>
                                                        </div>

                                                    </div>
                                                ) : (
                                                    <div className='item'>
                                                        <span className='txt-container'>{item}</span>
                                                        <div className='btn-wrap'>
                                                            <button className='btn btn-danger' onClick={() => deleteMessage(index)}>Delete</button>
                                                            <button className='btn' onClick={() => toggleEditMessageMessage(index, item)}>Edit</button>
                                                        </div>
                                                    </div>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="input-wrap ">
                                        <input
                                            type="text"
                                            placeholder="Type a message, then press Enter."
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    addMessage(e.target.value);
                                                    e.target.value = '';
                                                }
                                            }}
                                        />
                                    </div>

                                </div>

                                <button className='btn btn-info' onClick={submitUpdateMessageList} type="button" disabled={currentMessageList === messageList}>
                                    Save
                                </button>
                            </>
                        ) : "Loading..."}
                    </div>




                </section>
            </div>
        </div>
    )
}

export default AdminConfigPage;
const changeMainBackground = (inputNewClass) => {
    // Select the element with the ID 'mainBg'
    const mainBg = document.querySelector('#mainBg');

    if (mainBg) { // Check if the element exists
        // Get the list of classes for the element
        const classList = Array.from(mainBg.classList);

        // Remove classes with the 'mood-' prefix
        classList.forEach(className => {
            if (className.startsWith('mood-')) {
                mainBg.classList.remove(className);
            }
        });

        // Add the new class (for example, 'new-class')
        mainBg.classList.add(inputNewClass);
    }
};

export default changeMainBackground;
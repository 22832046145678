const getTodayDateGMT7 = () => {
    // Create a date object for the current date and time
    const date = new Date();

    // Convert to GMT+7 offset (7 hours * 60 minutes/hour * 60 seconds/minute * 1000 milliseconds/second)
    const offset = 7 * 60 * 60 * 1000;
    const localOffset = date.getTimezoneOffset() * 60 * 1000; // Get local timezone offset and convert to milliseconds
    const gmt7Date = new Date(date.getTime() + localOffset + offset);

    // Extract the year, month, and day
    const year = gmt7Date.getUTCFullYear();
    const month = (gmt7Date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, so +1; pad with zero if needed
    const day = gmt7Date.getUTCDate().toString().padStart(2, '0'); // pad with zero if needed

    // Return the formatted date
    return `${year}-${month}-${day}`;
};

export default getTodayDateGMT7;
import useMoodList from '../hooks/useMoodList';
import './MoodSubmitPage.scss';
import { HelmetMoodBoard } from '../components/CustomHelmet';

import useMessageList from '../hooks/useMessageList';
import { useState } from 'react';
import api from '../utils/api';  // Import the Axios instance you created
import getTodayDateGMT7 from '../utils/getTodayDateGMT7';
import changeMainBackground from '../utils/changeMainBackground';
import useElementFullHeight from '../hooks/useElementFullHeight';
import SpotifyIframe from '../components/SpotifyIframe';
import TypingText from '../components/TypingText';
import Footer from '../components/Footer';


const MoodSubmitPage = () => {
    const { moodList } = useMoodList();
    const { msgToSendList } = useMessageList();
    const [selectedMood, setSelectedMood] = useState(false);
    const [selectedMoodName, setselectedMoodName] = useState('');
    const [systemMessage, setSystemMessage] = useState(false);
    const [selectedSentMsg, setSelectedSentMsg] = useState(false);

    //When you click to the mood, active submit button
    const handleClickMood = (event) => {
        const moodKey = event.currentTarget.getAttribute('data-mood');
        setselectedMoodName(moodKey);
        setSelectedMood(moodList[moodKey]);
    }

    //When you click to submit button
    const handleSubmitMood = (event) => {
        if (selectedMood) {
            const randomIndex = Math.floor(Math.random() * selectedMood["msg"].length)
            setSystemMessage(selectedMood["msg"][randomIndex]);

            const date = getTodayDateGMT7();
            const mood = selectedMoodName;
            const params = {
                date: getTodayDateGMT7(),
                mood: selectedMoodName,
                msg: selectedSentMsg
            }
            api.put(`/update-mood`, params)
                .then(response => {
                    //socket.emit("messageAddMood", selectedMoodName);
                    changeMainBackground(`mood-${mood}`);
                })
                .catch(error => {
                    console.error(`Error updating mood count for date ${date} and mood ${mood}:`, error);
                });


        } else {
            setselectedMoodName('');
        }
    }

    //back to Home start
    const hanldeBackToStart = (event) => {
        setSystemMessage('');
        setselectedMoodName('');
        setSelectedMood('');
    }

    // useEffect(() => {
    //     return () => {
    //         socket.off("messageAddMood");
    //     };
    // }, []);

    //container height
    const elementHeight = useElementFullHeight('.element-to-exclude');

    return (
        <div className='moodsubmitpage' id="moodsubmitpage">
            <HelmetMoodBoard />
            <div className='container' style={{ minHeight: `${elementHeight}px` }}>

                {
                    systemMessage ? (<section className='message-zone' style={{ minHeight: `${elementHeight}px` }}>
                        <div className='mood-message'>
                            <img src={selectedMood["url"]} alt='' />
                            <div className='message'>
                                <TypingText message={systemMessage} />
                            </div>
                        </div>
                        <div className='music-box'>
                            <h2>Start the Jam!</h2>
                            <SpotifyIframe />
                        </div>

                        {/* <div className='action'>
                            <button onClick={hanldeBackToStart} className='btn back-btn'>
                                &larr; Back
                            </button>
                        </div> */}
                    </section>)
                        :

                        // initial screen
                        (
                            <section>
                                <h1 className='main-title'><b>How </b>do you <br /><b>feel</b> today?</h1>
                                <ul className={`mood-picker ${msgToSendList && msgToSendList.length ? 'smaller' : ''}`}>
                                    {
                                        moodList ? (
                                            Object.keys(moodList).map(key => (
                                                <li className={`mood ${selectedMoodName === key ? "selected" : ""}`} key={key} data-mood={key} onClick={handleClickMood}>
                                                    <img src={moodList[key]["url"]} alt={key} />
                                                    <p>{moodList[key]["text"]}</p>
                                                </li>
                                            ))
                                        ) : (<span>Loading...</span>)
                                    }
                                </ul>
                                {msgToSendList && msgToSendList.length ?
                                    <div className='mood-greeting'>
                                        <div className={`input-wrap ${selectedSentMsg ? `active` : ``}`}>
                                            <select
                                                id="selectBox"
                                                value={selectedSentMsg}
                                                onChange={(e) => setSelectedSentMsg(e.target.value)}>
                                                <option value="">Pick a message (optional)</option>

                                                {msgToSendList.map((msg, index) => (
                                                    <option key={index} value={msg}>{msg}</option>
                                                ))}

                                            </select>
                                        </div>

                                    </div> : ""}

                                <div className='action'>
                                    <button onClick={handleSubmitMood} className='btn submit-btn' disabled={!selectedMood}>
                                        Submit
                                    </button>
                                </div>
                            </section>
                        )
                }
            </div>
            <div className='container element-to-exclude'>
                <Footer />
            </div>
        </div>
    )
}

export default MoodSubmitPage;
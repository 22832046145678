import './NotFoundPage.scss';
import { Link } from 'react-router-dom';
import useElementFullHeight from '../hooks/useElementFullHeight';
import { CustomMyOwnHelmet } from '../components/CustomHelmet';


const NotFoundPage = () => {
    //container height
    const elementHeight = useElementFullHeight('.element-to-exclude');
    
    return (
        <div className='notfoundpage' id="notfoundpage">
            <CustomMyOwnHelmet title="VML VIETNAM | Page not found" />
            <div className='container' style={{ minHeight: `${elementHeight}px` }}>
                <section>
                    <h1 className='main-title'>Page <b>Not Found</b></h1>
                    <p>Sorry, the page you are looking for does not exist.</p>
                    <p>Please check the URL or navigate to the links below:</p>
                    <p>
                        <Link key='gotohomepage' to='/'>
                            🏠 Go to Homepage
                        </Link>
                    </p>
                </section>
            </div>
        </div>
    )
}

export default NotFoundPage;
import './DrawFrame.scss';

const DrawFrame = (props) => {
    return (
        <div className='draw-frame'>
            <div className='draw'>
                <img src={props.url} alt={`Photo Christmas`} />
            </div>
        </div>
    )
}

export default DrawFrame;
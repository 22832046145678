import logo from '../data/img/logo.png';

const Footer = () => {
    return (
        <footer>
            <strong>VIETNAM</strong><img src={logo} alt="VMLYR" /><span>EMOTIONS BOARD</span>
        </footer>
    )
}

export default Footer;
import { useState, useEffect } from 'react';
import './DrawDashboardPage.scss'
import { HelmetXmasDraw } from '../components/CustomHelmet';

import useElementFullHeight from '../hooks/useElementFullHeight';
import changeMainBackground from '../utils/changeMainBackground';

import SnowFall from '../components/SnowFall';

import { createSocketDrawDashboard } from "../utils/socket";
import api from '../utils/api';
import QRCode from "react-qr-code";


import urlTemplate from '../data/img/draw/template.jpg';
import urlCardTitle from '../data/img/draw/card/img_card_title.png';
import TypingText from '../components/TypingText';



const DrawDashboardPage = () => {

    const elementHeight = useElementFullHeight('.element-to-exclude');

    const DATA_URL = process.env.REACT_APP_DATA_URL;

    const drawingStyle = {
        backgroundImage: `url(${urlTemplate})`,
        backgroundSize: 'cover', // You can use 'contain' or other values based on your requirements
    };

    const [drawDataList, setDrawDataList] = useState([]);
    const [drawData, setDrawData] = useState({
        drawFilename: "",
        msg: "",
        sender: ""
    });
    const [currentIndex, setCurrentIndex] = useState(0);

    const exampleMsg = [
        "[🤖] Wishing you joy, peace, and love this holiday season. May your days be merry and bright, filled with laughter and warmth. Merry Christmas to you and your loved ones! 🎄🎅🎁",
        "[🤖] Wishing you a magical Christmas season filled with laughter, joy, and cherished moments. May the spirit of giving and love warm your heart and home. 🎄🎅🎁",
        "[🤖] Merry Christmas! May your days be merry, your nights be bright, and your heart be light. Here's to a season of love, peace, and festive delight. 🎄🎅🎁",
        "[🤖] Warmest wishes for a joyous Christmas and a New Year filled with prosperity and success. May the holiday spirit bring you closer to family and friends. 🎄🎅🎁",
        "[🤖] Merry Christmas! May the twinkling lights and festive decorations brighten your home, and the love of family and friends warm your heart throughout the season. 🎄🎅🎁",
        "[🤖] Sending you heartfelt wishes for a Merry Christmas and a Happy New Year. May the holidays bring you moments of happiness, reflection, and gratitude. 🎄🎅🎁",
        "[🤖] Merry Christmas to you and your loved ones! May the season be wrapped in love, and the New Year bring new opportunities and exciting adventures. 🎄🎅🎁",
        "[🤖] Wishing you a Christmas filled with warmth, laughter, and the company of those you hold dear. May the festive spirit bring peace and happiness to your home. 🎄🎅🎁",
        "[🤖] Merry Christmas! May the spirit of giving and the joy of the season be with you and your family. Here's to creating beautiful memories together. 🎄🎅🎁",
        "[🤖] Warm wishes for a festive and cozy Christmas. May the season bring you moments of reflection, gratitude, and the company of cherished ones. 🎄🎅🎁",
        "[🤖] Merry Christmas! May your heart be light, your days be merry, and your celebrations be filled with love and happiness. Here's to a magical holiday season. 🎄🎅🎁",
        "[🤖] Sending you warm Christmas greetings! May the holiday season be a time of joy, togetherness, and creating beautiful memories with your loved ones. 🎄🎅🎁",
        "[🤖] Merry Christmas and Happy Holidays! May your home be filled with the magic of the season, and may the New Year bring you hope, success, and fulfillment. 🎄🎅🎁",
        "[🤖] Wishing you a Christmas filled with love, laughter, and the warmth of family and friends. May the holiday spirit bring you peace and contentment. 🎄🎅🎁",
        "[🤖] Merry Christmas! May the festive decorations brighten your home and the joy of the season fill your heart. Here's to a magical and enchanting holiday. 🎄🎅🎁",
        "[🤖] Warm wishes for a Merry Christmas and a Happy New Year! May the holiday season be a time of reflection, gratitude, and joyful celebrations with your loved ones. 🎄🎅🎁",
        "[🤖] Merry Christmas! May the beauty of the season surround you, and the love of family and friends fill your days with warmth and happiness. 🎄🎅🎁",
        "[🤖] Wishing you a Christmas season filled with love, laughter, and the joy of creating special memories. May the New Year bring you exciting adventures and new beginnings. 🎄🎅🎁",
        "[🤖] Merry Christmas and Happy Holidays! May the magic of the season bring you moments of wonder and delight, and may the New Year be filled with possibilities. 🎄🎅🎁",
        "[🤖] Warmest wishes for a Merry Christmas and a joyful holiday season. May the spirit of giving and the joy of receiving fill your days with happiness.🎄🎅🎁",
        "[🤖] Merry Christmas to you and your family! May the holiday season be a time of reflection, gratitude, and togetherness. Here's to a bright and prosperous New Year! 🎄🎅🎁",
    ]

    const containsVietnamese = (text) => {
        const vietnameseRegex = /[àáảãạăắằẳẵặâầấẩẫậèéẻẽẹêềếểễệđìíỉĩịòóỏõọôồốổỗộơờớởỡợùúủũụưừứửữựỳýỷỹỵ]/iu;

        return vietnameseRegex.test(text);
    };

    const getData = () => {
        api.get(`/draw/1`)
            .then(response => {
                const data = response.data.data;

                //set random data
                setDrawDataList(shuffleArray(data));
            })
            .catch(error => {
                console.error(`Error fetching mood data}:`, error);
            });

    }

    const shuffleArray = (arr) => {
        const shuffledArray = [...arr];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }

        return shuffledArray;
    }

    useEffect(() => {
        const socket = createSocketDrawDashboard();
        changeMainBackground("christmas");

        getData();

        socket.on("messageToDrawDashboard", (data) => {
            setDrawDataList(prevList => [
                data,
                ...prevList
            ]);
        });

        socket.on("getDataDrawDashboard", () => {
            getData();
        });

        socket.on("reloadDrawDashboard", () => {
            window.location.reload();
        });

        // Cleanup: disconnect the socket when the component unmounts
        return () => {
            socket.disconnect();
        };

    }, []);

    useEffect(() => {
        let indx = 0;
        let intervalRenderDraw;

        clearInterval(intervalRenderDraw);

        const setupDrawData = (_drawData) => {
            if (!_drawData["sender"]) {
                _drawData["sender"] = "Anonymous";
            }

            if (_drawData["msg"] === '') {
                _drawData["msg"] = exampleMsg[Math.floor(Math.random() * exampleMsg.length)];
            }

            setDrawData(_drawData);
        }

        if (drawDataList[indx] && drawDataList[indx]["_id"]) {
            setupDrawData(drawDataList[indx]);
            indx++;
        }

        intervalRenderDraw = setInterval(() => {
            if (!drawDataList[indx]) {
                indx = 0;
            }

            if (drawDataList[indx] && drawDataList[indx]["_id"]) {
                setupDrawData(drawDataList[indx]);
                indx++;
            }

        }, 7500)

        return () => {
            clearInterval(intervalRenderDraw);
        };

    }, [drawDataList]);


    const DRAW_PAGE_URL = `${process.env.REACT_APP_URL}/draw`;

    return (
        <div className='drawdashboardpage' id="drawdashboardpage">
            <HelmetXmasDraw />
            <div className='container' style={{ minHeight: `${elementHeight}px` }}>
                <SnowFall />
                <div className='snowflake-group left'>
                    <div className='snowflake'></div>
                    <div className='snowflake'></div>
                    <div className='snowflake'></div>
                    <div className='snowflake'></div>
                    <div className='snowflake'></div>
                </div>
                <div className='snowflake-group right'>
                    <div className='snowflake'></div>
                    <div className='snowflake'></div>
                    <div className='snowflake'></div>
                    <div className='snowflake'></div>
                    <div className='snowflake'></div>
                    <div className='snowflake'></div>
                </div>
                <div className='christmas-card'>
                    <div className='markers'>
                        <div className='marker'></div>
                        <div className='marker'></div>
                        <div className='marker'></div>
                        <div className='marker'></div>
                    </div>
                    <div className='card-container'>
                        <div className='drawing' style={drawingStyle}>
                            <img src={`${DATA_URL}/${drawData.drawFilename}`} alt="" />
                        </div>
                        <div className='msg'>
                            <div className='title'>
                                <h1>
                                    <img src={urlCardTitle} alt='Merry Christmas - VML Vietnam' />
                                </h1>
                            </div>
                            <div className='content'>
                                <label className={containsVietnamese(drawData.sender) ? 'font-vietnamese' : ''}><span>From:</span>{drawData.sender}</label>
                                <TypingText propClass={containsVietnamese(drawData.msg) ? 'font-vietnamese' : ''} message={drawData.msg} speed={10} key={`${drawData._id + Math.round(Math.random() * 1E6)}`} />
                            </div>
                        </div>
                    </div>
                </div>
                <a className='qr-zone' href={DRAW_PAGE_URL}>
                    <p>Share</p>
                    <QRCode value={DRAW_PAGE_URL} bgColor="transparent" fgColor="#8E271D" />
                    <p>Your Wish</p>
                </a>
            </div>
        </div>
    )
}

export default DrawDashboardPage;
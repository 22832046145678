import React from 'react';

const SpotifyIframe = () => {
    return (
        <iframe
            style={{ borderRadius: '12px' }}
            src="https://open.spotify.com/embed/playlist/2Ex7hqLcqRf3fUhs7pPuhD?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            title="Spotify Playlist">
        </iframe>
    );
}

export default SpotifyIframe;

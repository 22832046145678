import api from './api'

const verifyUserToken = async () => {
    const userToken = localStorage.getItem('token') || "";

    if (!userToken) {
        return false;
    } else {
        let verified = false;
        await api.get('/protected-resource', {
            headers: {
                'Authorization': userToken, // Send the user token in the Authorization header
            },
        })
            .then((response) => {
                // Handle the response data from the protected resource
                verified = response.data.success;
            })
            .catch((error) => {
                // Handle errors, like unauthorized access
                console.error(error);
                verified = false;
            });
        return verified
    }


}


export default verifyUserToken;
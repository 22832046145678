import './LoginPage.scss'
import { CustomMyOwnHelmet } from '../components/CustomHelmet';
import api from '../utils/api';  // Import the Axios instance you created
import showNotification from '../utils/notify';
import useElementFullHeight from '../hooks/useElementFullHeight';
import { useNavigate, useSearchParams } from 'react-router-dom';
import verifyUserToken from '../utils/verifyUserToken';

import { useEffect, useState } from 'react';


const LoginPage = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    //Submit Login
    const handleLogin = (event) => {
        // Prevent page reload
        event.preventDefault();

        const { uname, pass } = document.forms[0];
        if (uname.value && pass.value) {
            const params = { username: uname.value, password: pass.value };

            api.post(`/login`, params)
                .then(response => {
                    if (response.data.token) {
                        showNotification("success", `You are logged in. You will be redirected to ${searchParams.get("direct_to") || "Home Page"}`);
                        localStorage.setItem('token', response.data.token);
                        setTimeout(() => {
                            navigate(`/${searchParams.get("direct_to") || ""}`)
                        }, 3000)
                    }
                })
                .catch(error => {
                    console.error(`login failed:`, error);
                    showNotification("error", error.message);
                });
        } else {

        }
    };

    //Log out
    const handleLogout = () => {
        // Remove the token from local storage
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        navigate('/login')
    };

    //container height
    const elementHeight = useElementFullHeight('.element-to-exclude');

    useEffect(() => {
        const checkAuthenticated = async () => {
            const result = await verifyUserToken();
            setIsAuthenticated(result);
        }
        checkAuthenticated();
    }, []);
    return (
        <div className='loginpage' id="loginpage">
            <CustomMyOwnHelmet title='VML VIETNAM | SIGN IN'/>
            <div className='container' style={{ minHeight: `${elementHeight}px` }}>
                <section>
                    {isAuthenticated ? (<h3>You are now logged in&nbsp;&nbsp;&nbsp;&nbsp;<button className='btn' onClick={handleLogout}>Log out</button></h3>) :
                        (
                            <div className="login-form">
                                <h1>Sign in</h1>

                                <form onSubmit={(event) => { handleLogin(event) }}>
                                    <div className="input-container">
                                        <label>Username </label>
                                        <input type="text" name="uname" required />
                                    </div>
                                    <div className="input-container">
                                        <label>Password </label>
                                        <input type="password" name="pass" required />
                                    </div>
                                    <div className="button-container">
                                        <button type='submit' className='btn'>Submit</button>
                                    </div>
                                </form>
                            </div>
                        )}


                </section>

            </div >
        </div >
    )
}

export default LoginPage;
import { useState, useEffect } from 'react';

import api from '../utils/api';  // Import the Axios instance you created

const useMessageList = () => {
    const [msgToSendList, setMsgToSendList] = useState(null);

    useEffect(() => {
        //get modd message
        api.get(`/mood-message`)
            .then(response => {
                setMsgToSendList(response.data.data || []);
            })
            .catch(error => {
                console.error(`Error:`, error);
            });

    }, []);

    return { msgToSendList };
}

export default useMessageList;
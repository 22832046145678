import { useState, useEffect } from 'react';
import './MoodDashboardPage.scss'
import { HelmetMoodBoard } from '../components/CustomHelmet';

import EmotionIcon from '../components/EmotionIcon';

import { createSocketDashboard } from "../utils/socket";
import api from '../utils/api';
import getTodayDateGMT7 from '../utils/getTodayDateGMT7';
import changeMainBackground from '../utils/changeMainBackground';

import QRCode from "react-qr-code";
import Marquee from "react-fast-marquee";

import useElementFullHeight from '../hooks/useElementFullHeight';
import Footer from '../components/Footer';

const DashboardPage = () => {
    //Function return a random animation
    const randomStyleAnimation = () => {
        const randomTop = Math.floor(Math.random() * 97);  // in vh
        const randomLeft = Math.floor(Math.random() * 95); // in vw
        const randomDuration = (Math.random() * 10) + 8; // Random animation duration between 8s and 18s
        const randomDelay = Math.random() * 0; // Random delay between 0s and 0s
        const animationType = Math.random() < 0.5 ? 'zoomIn' : 'zoomOut';  // Randomly choose zoom in or out

        //return a style details
        return {
            top: `${randomTop}vh`,
            left: `${randomLeft}vw`,
            animation: `${animationType} ${randomDuration}s infinite ${randomDelay}s`,
            animationDirection: 'alternate'
        }
    }

    //render current mood
    const [currentMoodList, setCurrentMoodList] = useState([]);
    const [currentMoodListData, setCurrentMoodListData] = useState({});


    const [messageList, setMessageList] = useState([]);

    const createMoodElement = (moodType, currentMoodListLength, isNew = false, theme = '', message = null) => {
        // Return the new EmotionIcon
        return <EmotionIcon key={currentMoodListLength} moodType={moodType} customStyle={randomStyleAnimation()} isNew={isNew} theme={theme} message={message} />;
    };

    //render seeding mood
    const [seedingMoodList, setSeedingMoodList] = useState([]);

    useEffect(() => {
        const socket = createSocketDashboard();

        socket.on("messageToDashboard", (data) => {
            // Append the new EmotionIcon to the list
            // setCurrentMoodList(prevList => [...prevList, createMoodElement(mood, prevList.length + 1)]);
            const mood = data.mood;
            const msg = data.msg;

            if (msg) {
                //set greeting messages
                setMessageList(prevList => {
                    return [...prevList, msg];
                });
            }

            setCurrentMoodList(prevList => {
                if (prevList.length >= 100) {
                    return [...prevList.slice(1), createMoodElement(mood, prevList.length + 1 + Math.random(), true)];
                }
                return [...prevList, createMoodElement(mood, prevList.length + 1 + Math.random(), true)];
            });

            //setCurrentMoodListData
            setCurrentMoodListData(prevList => {
                return { ...prevList, [mood]: prevList[mood] + 1 };
            });

        });

        socket.on("reloadDashboard", () => {
            window.location.reload();
        });

        // Cleanup: disconnect the socket when the component unmounts
        return () => {
            socket.disconnect();
        };

    }, []);

    useEffect(() => {
        const todayDate = getTodayDateGMT7();  // Example date

        api.get(`/mood-data/${todayDate}`)
            .then(response => {
                setCurrentMoodListData(response.data.count);
                const data = response.data.count;

                const keyWithMaxValue = Object.entries(data).reduce((prev, current) => {
                    return prev[1] > current[1] ? prev : current;
                })[0];

                changeMainBackground(`mood-${keyWithMaxValue}`);

                for (let mood in data) {
                    for (let i = 0; i < data[mood]; i++) {
                        // setCurrentMoodList(prevList => [...prevList, createMoodElement(mood, prevList.length + 1)]);
                        setCurrentMoodList(prevList => {
                            if (prevList.length >= 100) {
                                return [...prevList.slice(1), createMoodElement(mood, prevList.length + 1 + Math.random())];
                            }
                            return [...prevList, createMoodElement(mood, prevList.length + 1 + Math.random())];
                        });

                    }
                }
            })
            .catch(error => {
                console.error(`Error fetching mood data for date ${todayDate}:`, error);
            });

        //Get theme and seeding icons
        api.get(`/mood-theme`)
            .then(response => {
                const moodTheme = response.data.data.value || "";

                api.get(`/mood-seeding`)
                    .then(response => {
                        const seedingCount = response.data.data || 0;

                        if (seedingCount) {
                            // Create an array of items based on itemCount
                            const seedingElements = [];

                            for (let i = 1; i <= seedingCount; i++) {
                                seedingElements.push(
                                    createMoodElement(`seasonal`, `seeding_${i}`, false, moodTheme),
                                );
                            }
                            setSeedingMoodList(seedingElements);

                        }
                    })
                    .catch(error => {
                        console.error(`Error fetching mood theme.`, error);
                    });


            })
            .catch(error => {
                console.error(`Error fetching mood theme.`, error);
            });


    }, [])

    useEffect(() => {
        if (Object.keys(currentMoodListData).length > 0) {
            const keyWithMaxValue = Object.entries(currentMoodListData).reduce((prev, current) => {
                return prev[1] > current[1] ? prev : current;
            })[0];
            changeMainBackground(`mood-${keyWithMaxValue}`);
        }

    }, [currentMoodListData]);


    //container height
    const elementHeight = useElementFullHeight('.element-to-exclude');

    //QR code url
    const HOME_PAGE_URL = process.env.REACT_APP_URL;

    return (
        <div className='dashboardpage' id="dashboardpage">
            <HelmetMoodBoard />
            <div className='container' style={{ minHeight: `${elementHeight}px` }}>
                <section>
                    <div className='analytics-zone'>
                        <div className='mood-count-zone'>
                            <EmotionIcon key="moodCountHappy" moodType={"happy"} />
                            <span>{currentMoodListData["happy"]}</span>
                        </div>
                        <div className='mood-count-zone'>
                            <EmotionIcon key="moodCountEnergetic" moodType={"energetic"} />
                            <span>{currentMoodListData["energetic"]}</span>
                        </div>
                        <a href='/mood' className='qr-zone'>
                            <h1>
                                <span><strong>How</strong></span>
                                <span>do you</span>
                                <span><strong>feel</strong></span>
                                <span>today?</span>
                            </h1>
                            {/* SVG QR */}
                            <QRCode value={`${HOME_PAGE_URL}/mood`} bgColor="transparent" fgColor="white" />
                        </a>
                        <div className='mood-count-zone'>
                            <EmotionIcon key="moodCountSad" moodType={"sad"} />
                            <span>{currentMoodListData["sad"]}</span>
                        </div>
                        <div className='mood-count-zone'>
                            <EmotionIcon key="moodCountSwing" moodType={"swing"} />
                            <span>{currentMoodListData["swing"]}</span>
                        </div>
                    </div>


                    <div className='emotion-zone'>
                        {messageList.length ? (
                            <Marquee>
                                {messageList.map((msg, index) => (
                                    <span key={index}>{msg}</span>
                                ))}
                            </Marquee>
                        ) : ""}
                        <div>
                            {seedingMoodList}
                        </div>
                        {currentMoodList}
                    </div>

                </section>
            </div>
            <div className='container element-to-exclude'>
                <Footer />
            </div>
        </div>
    )
}

export default DashboardPage;
import React, { useState, useEffect } from 'react';

function EmotionIcon({ moodType = "happy", customStyle = {}, isNew = false, theme = '', message = null }) {
    const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsActive(false);
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <>
            <div className={`emotion-icon ${theme} ${isActive ? `fly-from-center ${isNew ? 'new-icon' : ''}` : ''} ${message ? `message-box` : ``}`} mood-type={moodType} style={customStyle}>
                <span>{message}</span>
            </div>
        </>
    )
}

export default EmotionIcon;
import useTypingEffect from '../hooks/useTypingEffect';

const TypingText = (props) => {
    const { message, speed, propClass} = props; // Extract message from props

    const displayedMessage = useTypingEffect(message, speed); // Use the custom hook

    return (
        <p className={propClass}>
            {displayedMessage}
        </p>
    );
}

export default TypingText;

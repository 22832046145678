import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import AdminConfigPage from '../pages/admin/AdminConfigPage';
import AdminConfigDrawPage from '../pages/admin/AdminConfigDrawPage';
import verifyUserToken from '../utils/verifyUserToken';


const AdminGuard = (props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const checkAuthenticated = async () => {
            const result = await verifyUserToken();
            setIsAuthenticated(result);
        }
        checkAuthenticated();
    }, []);


    if (isAuthenticated === null) {
        // Wait for async function to complete
        return <div></div>;
    } else if (isAuthenticated) {
        // User is authenticated, allow access to AdminConfigPage
        if (props.pageName === "admin")
            return <AdminConfigPage />;
        if (props.pageName === "admin-draw")
            return <AdminConfigDrawPage />;
    } else if (!isAuthenticated) {
        // User is not authenticated, redirect to the login page
        const redirectParams = props.pageSlug ? `?direct_to=${props.pageSlug}` : '';
        return <Navigate to={`/login${redirectParams}`} />;
    }

};

export default AdminGuard;
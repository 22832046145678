import { useState, useEffect } from 'react';

const useElementFullHeight = (excludeSelector) => {
    // Container height
    const [elementHeight, setElementHeight] = useState(window.innerHeight);

    // Function to calculate page height
    const calculateElementHeight = () => {
        const elementsToExclude = document.querySelectorAll(excludeSelector);
        const totalExcludedHeight = Array.from(elementsToExclude).reduce(
            (totalHeight, element) => totalHeight + element.clientHeight, 0
        );

        setElementHeight(window.innerHeight - totalExcludedHeight - 2);
    }

    useEffect(() => {
        window.addEventListener('resize', calculateElementHeight);
        // Initial calculation
        calculateElementHeight();
        // Cleanup
        return () => {
            window.removeEventListener('resize', calculateElementHeight);
        };
    }, []);

    return elementHeight;
}

export default useElementFullHeight;

import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { NotificationContainer } from 'react-notifications';

import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';

import AdminGuard from './components/AdminGuard';

import MoodSubmitPage from './pages/MoodSubmitPage';
import MoodDashboardPage from './pages/MoodDashboardPage';
import MoodAnalyticsPage from './pages/MoodAnalyticsPage';

import DrawPage from './pages/DrawPage';
import DrawDashboardPage from './pages/DrawDashboardPage';
import DrawAnalyticsPage from './pages/DrawAnalyticsPage';
import DrawGalleryPage from './pages/DrawGalleryPage';

const App = () => {
  return (
    <BrowserRouter>
      <div className="main">
        <div className="orb-bg" id='mainBg'>
          {/* <div className="ball"></div>
         <div
           className="ball"
           style={{
             "--delay": "-10s",
             "--size": "0.5",
             "--speed": "20s"
           }}
         ></div>
         <div
           className="ball"
           style={{
             "--delay": "-8s",
             "--size": "0.3",
             "--speed": "12s"
           }}
         ></div> */}
        </div>
        <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/login" element={<LoginPage />} />

          <Route path="/mood" element={<MoodSubmitPage />} />
          <Route path="/mood-dashboard" element={<MoodDashboardPage />} />
          <Route path="/mood-analytics" element={<MoodAnalyticsPage />} />
          <Route path="/mood-config" element={<AdminGuard pageName={"admin"} pageSlug={"mood-config"} />} />

          <Route path="/draw" element={<DrawPage />} />
          <Route path="/draw-dashboard" element={<DrawDashboardPage />} />
          <Route path="/draw-analytics" element={<DrawAnalyticsPage />} />
          <Route path="/draw-gallery" element={<DrawGalleryPage />} />
          <Route path="/draw-config" element={<AdminGuard pageName={"admin-draw"} pageSlug={"draw-config"} />} />

          {/* "Not Found" route */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <>
          <NotificationContainer />
        </>
      </div>
    </BrowserRouter>
  );
};

export default App;